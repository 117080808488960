export default [
  {
    path: '/contract-finder',
    name: 'contract-finder',
    component: () => import('@/views/e-contract/find/ContractFinder.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/sign-contract',
    name: 'sign-contract',
    component: () => import('@/views/e-contract/sign/SignContract.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/sign-contract-v2',
    name: 'sign-contract-v2',
    component: () => import('@/views/e-contract/sign/SignContractV2.vue'),
    meta: {
      layout: 'full',
    },
  },
];
