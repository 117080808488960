import useJwt from "@/auth/jwt/useJwt";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getReportFinanceByYear(ctx, params) {
      return useJwt.getReportFinanceByYear(params).then(response => response);
    },
    getReportPropertyGeneral(ctx, params) {
      return useJwt.getReportPropertyGeneral(params).then(response => response);
    },
    getReportPropertyDetail(ctx, params) {
      return useJwt.getReportPropertyDetail(params).then(response => response);
    },
    getReportPropertyCounting(ctx, params) {
      return useJwt.getReportPropertyCounting(params).then(response => response);
    },
    getReportFinanceCashFlow(ctx, params) {
      return useJwt.getReportFinanceCashFlow(params).then(response => response);
    },
    getReportFinanceCounting(ctx, params) {
      return useJwt.getReportFinanceCounting(params).then(response => response);
    },
    getReportFinanceDeposit(ctx, params) {
      return useJwt.getReportFinanceDeposit(params).then(response => response);
    },
    exportReportFinanceDeposit(ctx, params) {
      return useJwt.exportReportFinanceDeposit(params).then(response => response);
    },
    exportReportBillingCalendar(ctx, params) {
      return useJwt.exportReportBillingCalendar(params).then(response => response);
    },
    getAnalyticsReportFinanceByMonth(ctx, params) {
      return useJwt.getAnalyticsReportFinanceByMonth(params).then(response => response);
    },
    exportAnalyticsReportFinanceByMonth(ctx, params) {
      return useJwt.exportAnalyticsReportFinanceByMonth(params).then(response => response);
    },
    getReportFinancePrepaid(ctx, params) {
      return useJwt.getReportFinancePrepaid(params).then(response => response);
    },
    getReportFinanceByMonth(ctx, params) {
      return useJwt.getReportFinanceByMonth(params).then(response => response);
    },
    getReportDebt(ctx, params) {
      return useJwt.getReportFinanceDebt(params).then(response => response);
    },
    getReportBillingCalendar(ctx, params) {
      return useJwt.getReportBillingCalendar(params).then(response => response);
    },
    exportReportDebt(ctx, params) {
      return useJwt.exportReportFinanceDebt(params).then(response => response);
    },
  },
};
