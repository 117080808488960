export default [
  {
    path: '/report/property',
    name: 'report-property',
    component: () => import('@/views/report/property/ReportProperty.vue'),
    meta: {
      resource: 'report-property',
      action: 'read',
    },
  },
  {
    path: '/report/finance',
    name: 'report-finance',
    component: () => import('@/views/report/finance/ReportFinance.vue'),
    meta: {
      resource: 'report-finance',
      action: 'read',
    },
  },
  {
    path: '/report/finance-by-month',
    name: 'report-finance-by-month',
    component: () => import('@/views/report/finance/finance-by-month/FinanceReportByMonth.vue'),
    meta: {
      resource: 'report-finance',
      action: 'read',
    },
  },
  {
    path: '/report/tenant',
    name: 'report-tenant',
    component: () => import('@/views/report/tenant/ReportTenant.vue'),
    meta: {
      resource: 'report-tenant',
      action: 'read',
    },
  },
  {
    path: '/report/task',
    name: 'report-task',
    component: () => import('@/views/report/task/ReportTask.vue'),
    meta: {
      resource: 'report-task',
      action: 'read',
    },
  },
  {
    path: '/report/asset',
    name: 'report-asset',
    component: () => import('@/views/report/asset/ReportAsset.vue'),
    meta: {
      resource: 'report-asset',
      action: 'read',
    },
  },
  {
    path: '/report/property/empty-rooms',
    name: 'report-empty-room',
    component: () => import('@/views/report/property/empty-room/ReportEmptyRoom.vue'),
    meta: {
      resource: 'report-property',
      action: 'read',
    },
  },
  {
    path: '/report/property/near-empty-rooms',
    name: 'report-near-empty-room',
    component: () => import('@/views/report/property/near-empty-room/ReportNearEmptyRoom.vue'),
    meta: {
      resource: 'report-property',
      action: 'read',
    },
  },
  {
    path: '/report/finance/deposit',
    name: 'report-finance-deposit',
    component: () => import('@/views/report/finance/deposit/ReportDeposit.vue'),
    meta: {
      resource: 'report-finance',
      action: 'read',
    },
  },
  {
    path: '/report/finance/debt',
    name: 'report-debt',
    component: () => import('@/views/report/finance/debt/ReportDebt.vue'),
    meta: {
      resource: 'report-finance',
      action: 'read',
    },
  },
  {
    path: '/report/finance/cash-flow',
    name: 'report-cash-flow',
    component: () => import('@/views/report/finance/cash-flow/ReportCashFlow.vue'),
    meta: {
      resource: 'report-finance',
      action: 'read',
    },
  },
  {
    path: '/report/finance/billing-calendar',
    name: 'report-billing-calendar',
    component: () => import('@/views/report/finance/billing-calendar/BillingCalendar.vue'),
    meta: {
      resource: 'report-finance',
      action: 'read',
    },
  },
];
